


































































































































@import '~@/assets/styles/components/formularios';
@import '~@/assets/styles/components/modal-cadastro';
.box-table .v-data-table > .v-data-table__wrapper > table > tbody > tr > td span.color-red{
  color: lightcoral;
}
